import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import styled from "styled-components";
import Header from "./header/Header";
import Aside from "./Aside/Aside";
import { useToggleMenu } from "../common/store";
import Login from "../Login";
import Error from "./Error/Error";

// Lazy-load components from shared packages
const RoutingOnboardingBjs = lazy(() =>
  import("@bjsdistribution/tms-onboarding").then((module) => ({
    default: module.RoutingOnboardingBjs,
  }))
);

const Report = lazy(() =>
  import("@bjsdistribution/tms-onboarding").then((module) => ({
    default: module.Report,
  }))
);

const AddVehicle = lazy(() =>
  import("@bjsdistribution/tms-add-vehicle").then((module) => ({
    default: module.AddVehicle,
  }))
);

const RoutingDefectsBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingDefectsBjs,
  }))
);

const RoutingBreakdownBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingBreakdownBjs,
  }))
);

const RoutingChecksBjs = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingChecksBjs,
  }))
);

const RoutingMaintenance = lazy(() =>
  import("@bjsdistribution/tms-vehicle-maintenance").then((module) => ({
    default: module.RoutingMaintenance,
  }))
);

function AppRouter() {
  const [toggleStatus, setToggleStatus] = useToggleMenu();

  const LoadingMessage = () => <div>Loading...</div>;

  return (
    <Router>
      <Login />
      <MainContainer className={toggleStatus ? "activeMenu" : ""}>
        <Aside />
        <div className="dashboard">
          <Header />
          <div className="placeHolder">
            <Suspense fallback={<LoadingMessage />}>
              <Routes>
                {/* Redirects */}
                <Route path="/" element={<Navigate to="/onboarding/overview" replace />} />
                <Route path="/onboarding" element={<Navigate to="/onboarding/overview" replace />} />
                <Route path="/onboarding/*" element={<RoutingOnboardingBjs />} />
                <Route path="/onboarding/report" element={<Report />} />

                {/* Add Vehicle Routes */}
                {/* <Route path="/add-vehicle/*" element={<Navigate to="/add-vehicle/vehicle-information" replace />} /> */}
                <Route path="/add-vehicle/*" element={<AddVehicle />} />

                {/* Maintenance Routes */}
                <Route path="/maintenance" element={<Navigate to="/maintenance/maintain" replace />} />
                <Route path="/maintenance/*" element={<RoutingMaintenance />} />

                <Route path="/maintenance/vehicle-defects" element={<Navigate to="/maintenance/vehicle-defects/new-defects" replace />} />
                <Route path="/maintenance/vehicle-defects/*" element={<RoutingDefectsBjs />} />
                <Route path="/maintenance/vehicle-checks/*" element={<RoutingChecksBjs />} />
                <Route path="/maintenance/breakdowns/*" element={<RoutingBreakdownBjs />} />

                {/* Fallback Route */}
                <Route path="*" element={<Error />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </MainContainer>
    </Router>
  );
}

const MainContainer = styled.div`
  display: flex;

  .dashboard {
    flex: 1;
    background: #eaedf7;
    transition: all ease 0.5s;
    .placeHolder {
      padding: 25px 20px 10px 20px;
    }
  }
`;

export default AppRouter;
