import React from "react";
import styled from "styled-components";
import ArrowDownIcon from "../../../assets/icons/ArrowDownIcon";
import { useUserDetails } from "../../common/store";

const SuperAdmin = () => {
  const [userDetails] = useUserDetails();
  return (
    <>
      <SuperAdminWrapper>
        <div className="profileWrap">
          <img src="/assets/images/user.png"></img>
        </div>
        <div className="role">
          <strong>{userDetails || ""}</strong>
          {/* <span>Admin</span> */}
        </div>
        <div className="toggleIcon">
          <ArrowDownIcon />
        </div>
      </SuperAdminWrapper>
    </>
  );
};

export default SuperAdmin;

const SuperAdminWrapper = styled.div`
  display: flex;
  gap: 7px;
  .profileWrap {
    padding-right: 4px;
    width: 41px;
    height: 41px;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
  }
  .role {
    strong,
    span {
      display: block;
      font-style: normal;
    }
    strong {
      color: ${({ theme }) => theme.primaryBlue};
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
    span {
      color: ${({ theme }) => theme.lightGray};
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .toggleIcon {
    padding-top: 7px;
  }
`;
