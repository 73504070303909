export const menuItems = [
  {
    id: "M0",
    name: "Dashboard",
    iconName: "dashboard",
    url: "/dashboard",
  },

  {
    id: "M1",
    name: "Onboarding",
    iconName: "onboarding",
    url: "/onboarding",
    subMenu: [
      // {
      //   name: "New Request",
      //   url: "",
      //   disabled: true,
      // },
      {
        name: "Overview",
        url: "onboarding/overview",
      },
      {
        name: "report",
        url: "onboarding/report",
      },
    ],
  },

  {
    id: "M2",
    name: "Vehicles",
    iconName: "vehicles",
    url: "/add-vehicle",
    subMenu: [
      {
        name: "Owned",
        url: "/owned",
      },
      {
        name: "Leased Vehicle",
        url: "/leased-vehicle",
      },
      {
        name: "Hired Vehicle",
        url: "/hired-vehicle",
      },
      {
        name: "Add Vehicle",
        url: "/add-vehicle",
      },
    ],
  },

  {
    id: "M3",
    name: "HR",
    iconName: "drivers",
    url: "/hr",
  },

  {
    id: "M4",
    name: "Clients",
    iconName: "clients",
    url: "/clients",
  },
  {
    id: "M5",
    name: "Maintenance",
    iconName: "maintenance",
    url: "/maintenance",
    subMenu: [
      {
        name: "Maintenance",
        url: "maintenance/maintain",
      },
      {
        name: "Vehicle Defect’s",
        url: "/maintenance/vehicle-defects",
      },
      {
        name: "Vehicle Checks",
        url: "/maintenance/vehicle-checks",
      },
      {
        name: "Breakdown's",
        url: "/maintenance/breakdowns",
      },

      {
        name: "Accident",
        url: "/maintenance/accident",
      },
    ],
  },
  {
    id: "M6",
    name: "Assets",
    iconName: "assets",
    url: "/assets",
  },
  {
    id: "M7",
    name: "Finance",
    iconName: "finance",
    url: "/finance",
  },
];
