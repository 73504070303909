import React, { useState } from "react";
import AppRouter from "./layout/AppRouter";
import styled, { ThemeProvider } from "styled-components";
import { darkTheme, GlobalStyles, lightTheme } from "@bjsdistribution/tms-component-library";
import { SettingOutlined } from "@ant-design/icons";
function App() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  return (
    <>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <Setting onClick={toggleTheme}>
          <SettingOutlined /> Change Theme
        </Setting>

        <AppRouter />
        <GlobalStyles />
      </ThemeProvider>
    </>
  );
}
const Setting = styled.div`
  position: absolute;
  bottom: 25px;
  font-size: 16px;
  color: #fff;
  padding-left: 20px;
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

export default App;
